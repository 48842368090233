import { Product, PreOrder } from "../services/api";

// Define the state shape
export interface AppState {
  products: Product[];
  cartItems: { [key: string]: number };
  preOrders: PreOrder[];
  user: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    details: {
      email: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  loading: boolean;
  error: string | null;
}

// Define action types
export type AppAction =
  | { type: "SET_PRODUCTS"; payload: Product[] }
  | { type: "UPDATE_CART"; payload: { [key: string]: number } }
  | { type: "SET_PRE_ORDERS"; payload: PreOrder[] }
  | {
      type: "SET_USER";
      payload: {
        isAuthenticated: boolean;
        isAdmin: boolean;
        details: AppState["user"]["details"];
      };
    }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null };

// Initial state
export const initialState: AppState = {
  products: [],
  cartItems: {},
  preOrders: [],
  user: {
    isAuthenticated: false,
    isAdmin: false,
    details: null,
  },
  loading: false,
  error: null,
};

// Add this logger function
const logger = (action: AppAction, state: AppState, nextState: AppState) => {
  if (process.env.NODE_ENV === "development") {
    console.group(
      `%cAction: ${action.type}`,
      "color: #4CAF50; font-weight: bold;"
    );
    console.log(
      "%cPrevious State:",
      "color: #9E9E9E; font-weight: bold;",
      state
    );
    console.log("%cAction:", "color: #03A9F4; font-weight: bold;", action);
    console.log(
      "%cNext State:",
      "color: #FF5722; font-weight: bold;",
      nextState
    );
    console.groupEnd();
  }
};

// Modify the reducer function
export function appReducer(state: AppState, action: AppAction): AppState {
  let nextState: AppState;

  switch (action.type) {
    case "SET_PRODUCTS":
      nextState = { ...state, products: action.payload };
      break;
    case "UPDATE_CART":
      nextState = { ...state, cartItems: action.payload };
      break;
    case "SET_PRE_ORDERS":
      nextState = { ...state, preOrders: action.payload };
      break;
    case "SET_USER":
      nextState = { ...state, user: action.payload };
      break;
    case "SET_LOADING":
      nextState = { ...state, loading: action.payload };
      break;
    case "SET_ERROR":
      nextState = { ...state, error: action.payload };
      break;
    default:
      nextState = state;
  }

  logger(action, state, nextState);
  return nextState;
}
