import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../services/api";
import { CartSummary } from "./CartSummary";

interface ProductListProps {
  onAddToCart: (selections: { [key: string]: number }) => void;
  cartItems: { [key: string]: number };
  products: Product[];
}

interface GroupedProduct {
  base_name: string;
  description: string;
  ingredients: string;
  variants: Product[];
}

export const ProductList: React.FC<ProductListProps> = ({
  onAddToCart,
  cartItems,
  products,
}) => {
  const navigate = useNavigate();

  // Add state for selected variants
  const [selectedVariants, setSelectedVariants] = useState<{
    [key: string]: number;
  }>({});

  // Group products and set initial selected variants
  const groupedProducts = useMemo(() => {
    const grouped = products.reduce(
      (acc: { [key: string]: GroupedProduct }, product) => {
        if (!acc[product.base_name]) {
          acc[product.base_name] = {
            base_name: product.base_name,
            description: product.description,
            ingredients: product.ingredients,
            variants: [],
          };
        }

        const isDefaultVariant = product.size === "4oz";

        if (isDefaultVariant) {
          setSelectedVariants((prev) => ({
            ...prev,
            [product.base_name]: product.id,
          }));
        }

        acc[product.base_name].variants.push(product);
        return acc;
      },
      {}
    );
    return grouped;
  }, [products]);

  const handleSizeChange = (baseName: string, variantId: number) => {
    setSelectedVariants((prev) => ({
      ...prev,
      [baseName]: variantId,
    }));
  };

  const handleQuantityChange = (productId: string, change: number) => {
    const newQuantity = Math.max(0, (cartItems[productId] || 0) + change);
    onAddToCart({ [productId]: newQuantity });
  };

  return (
    <>
      <div className="container mx-auto p-8 pb-32">
        <h1 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
          Our Products
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Object.values(groupedProducts).map((group) => {
            const selectedVariant =
              group.variants.find(
                (v) => v.id === selectedVariants[group.variants[0].base_name]
              ) || group.variants[0];

            return (
              <div
                key={group.variants[0].base_name}
                className="border border-neutral-200 rounded-lg p-6 shadow-sm flex flex-col justify-between 
                         transition duration-300 ease-in-out hover:shadow-md hover:border-primary-300"
              >
                <div>
                  {selectedVariant.image_url && (
                    <div
                      className="mb-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/products/${encodeURIComponent(group.base_name)}`
                        );
                      }}
                    >
                      <img
                        src={selectedVariant.image_url}
                        alt={selectedVariant.display_name}
                        className="w-full h-48 object-cover rounded-lg hover:opacity-90 transition-opacity"
                      />
                    </div>
                  )}

                  <h2
                    className="font-cabinet-grotesk text-xl font-semibold text-neutral-800 mb-2 cursor-pointer hover:text-primary-600"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/products/${encodeURIComponent(group.base_name)}`
                      );
                    }}
                  >
                    {group.base_name}
                  </h2>

                  {group.ingredients && (
                    <div className="mb-4">
                      <h3 className="text-sm font-semibold text-neutral-700 mb-1">
                        Ingredients:
                      </h3>
                      <p className="text-neutral-600 text-sm">
                        {group.ingredients}
                      </p>
                    </div>
                  )}

                  <div className="flex justify-between items-center mb-4">
                    <select
                      value={selectedVariant.id}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSizeChange(
                          selectedVariant.base_name,
                          Number(e.target.value)
                        );
                      }}
                      className="px-3 py-2 border border-neutral-300 rounded-md text-neutral-600 focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:border-[#34324B]"
                    >
                      {group.variants.map((variant) => (
                        <option key={variant.id} value={variant.id}>
                          {variant.size}
                        </option>
                      ))}
                    </select>
                    <span className="text-[#34324B] font-semibold text-lg">
                      ${Number(selectedVariant.price).toFixed(2)}
                    </span>
                  </div>

                  <div className="flex items-center justify-between mt-4">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(selectedVariant.id.toString(), -1);
                      }}
                      className="w-8 h-8 rounded-full bg-neutral-100 flex items-center justify-center hover:bg-neutral-200 focus:outline-none transition duration-200 ease-in-out"
                      disabled={!cartItems[selectedVariant.id]}
                    >
                      <span className="text-neutral-600 font-bold">-</span>
                    </button>
                    <span className="text-lg font-semibold text-neutral-700">
                      {cartItems[selectedVariant.id] || 0}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(selectedVariant.id.toString(), 1);
                      }}
                      className="w-8 h-8 rounded-full bg-neutral-100 flex items-center justify-center hover:bg-neutral-200 focus:outline-none transition duration-200 ease-in-out"
                    >
                      <span className="text-neutral-600 font-bold">+</span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CartSummary cartItems={cartItems} products={products} />
    </>
  );
};
