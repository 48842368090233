import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../services/auth";

export const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = auth.isAuthenticated();
  const isAdmin = auth.isAdmin();

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link
          to="/"
          className="text-2xl font-cabinet-grotesk font-bold text-neutral-800"
        >
          but her face
        </Link>
        <div className="space-x-4">
          <Link
            to="/products"
            className="text-neutral-600 hover:text-[#34324B] transition duration-200"
          >
            Products
          </Link>

          {isAuthenticated ? (
            <>
              <Link
                to="/my-pre-orders"
                className="text-neutral-600 hover:text-[#34324B]"
              >
                My Pre-Orders
              </Link>
              {isAdmin && (
                <Link
                  to="/admin"
                  className="text-neutral-600 hover:text-[#34324B]"
                >
                  Admin Portal
                </Link>
              )}
              <button
                onClick={handleLogout}
                className="text-neutral-600 hover:text-[#34324B] font-medium"
              >
                Logout
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className="text-neutral-600 hover:text-[#34324B] font-medium"
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};
