import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../services/auth";

export const VerifyToken: React.FC = () => {
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      const returnTo = searchParams.get("returnTo") || "/";

      if (!token) {
        setError("No token provided");
        setVerifying(false);
        return;
      }

      try {
        const { isValid, authToken } = await auth.verifyToken(token);
        if (isValid) {
          auth.setToken({
            token,
            expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
            role: authToken?.role,
          });
          navigate(returnTo);
        } else {
          setError("Invalid or expired token");
        }
      } catch (err) {
        setError("An error occurred while verifying the token");
      }

      setVerifying(false);
    };

    verifyToken();
  }, [location, navigate]);

  if (verifying) {
    return <div>Verifying token...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};
