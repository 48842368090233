import React from "react";

export const AdminProducts: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Products Management</h2>
      {/* Add products management content here */}
    </div>
  );
};
