import React, { useReducer, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./components/AppRoutes";
import { Navigation } from "./components/Navigation";
import { appReducer, initialState } from "./reducers/appReducer";
import { api } from "./services/api";
import { auth } from "./services/auth";

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const fetchUserData = async () => {
    try {
      const [userDetails, preOrders] = await Promise.all([
        api.getUserDetails(),
        api.getUserPreOrders(),
      ]);
      dispatch({ type: "SET_PRE_ORDERS", payload: preOrders });
      dispatch({
        type: "SET_USER",
        payload: {
          isAuthenticated: true,
          isAdmin: auth.isAdmin(),
          details: userDetails,
        },
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch({
        type: "SET_ERROR",
        payload:
          error instanceof Error ? error.message : "An unknown error occurred",
      });
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const products = await api.getProducts();
        dispatch({ type: "SET_PRODUCTS", payload: products });

        if (auth.isAuthenticated()) {
          await fetchUserData();
        } else {
          dispatch({
            type: "SET_USER",
            payload: {
              isAuthenticated: false,
              isAdmin: false,
              details: null,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
        dispatch({
          type: "SET_ERROR",
          payload:
            error instanceof Error
              ? error.message
              : "An unknown error occurred",
        });
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    fetchInitialData();
  }, []);

  const isAuthenticated = auth.isAuthenticated();
  useEffect(() => {
    if (auth.isAuthenticated()) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  const handleAddToCart = (selections: { [key: string]: number }) => {
    dispatch({
      type: "UPDATE_CART",
      payload: { ...state.cartItems, ...selections },
    });
  };

  return (
    <Router>
      <div className="App">
        <Navigation />
        <AppRoutes
          cartItems={state.cartItems}
          onAddToCart={handleAddToCart}
          products={state.products}
          preOrders={state.preOrders}
          user={state.user}
          loading={state.loading}
          error={state.error}
          dispatch={dispatch}
        />
      </div>
    </Router>
  );
}

export default App;
