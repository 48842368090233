import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../services/auth";

interface PrivateRouteProps {
  children: React.ReactNode;
  adminOnly?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  adminOnly = false,
}) => {
  if (!auth.isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && !auth.isAdmin()) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
