import React, { useState, useEffect } from "react";
import { api, PreOrder } from "../services/api";
import { auth } from "../services/auth";
import { useNavigate } from "react-router-dom";
import getStatusStyle from "../utils/orderStyles";

// Add this helper function to check if status has changed
const hasStatusChanged = (
  orderId: number,
  currentStatus: string,
  selectedStatus: Record<number, string>
) => {
  return selectedStatus[orderId] && selectedStatus[orderId] !== currentStatus;
};

export const AdminPreOrders: React.FC = () => {
  const [preOrders, setPreOrders] = useState<PreOrder[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<PreOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<Record<number, string>>(
    {}
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreOrders = async () => {
      try {
        if (!auth.isAuthenticated() || !auth.isAdmin()) {
          setError("Unauthorized access");
          return;
        }

        const data = await api.getAllPreOrders();
        setPreOrders(data);
        setFilteredOrders(data);
      } catch (err: any) {
        console.error("Error fetching pre-orders:", err);
        if (err.message === "Invalid token. Please log in again.") {
          auth.logout();
          navigate("/login");
        } else {
          setError("Failed to fetch pre-orders. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPreOrders();
  }, [navigate]);

  useEffect(() => {
    const filtered = preOrders.filter(
      (order) =>
        (statusFilter ? order.status === statusFilter : true) &&
        (searchTerm
          ? order.id.toString().includes(searchTerm) ||
            order.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.user?.firstName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            order.user?.lastName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            order.user?.email?.toLowerCase().includes(searchTerm.toLowerCase())
          : true)
    );
    setFilteredOrders(filtered);
  }, [searchTerm, preOrders, statusFilter]);

  const handleStatusUpdate = async (orderId: number) => {
    const newStatus = selectedStatus[orderId];
    if (!newStatus) return;

    try {
      await api.updatePreOrderStatus(orderId, newStatus);
      setPreOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (err) {
      console.error("Error updating pre-order status:", err);
      alert("Failed to update pre-order status. Please try again.");
    }
  };

  const filterButtons = [
    "All",
    "Pending",
    "Processing",
    "Shipped",
    "Delivered",
    "Cancelled",
  ];

  if (loading) return <div className="text-center py-12">Loading...</div>;
  if (error)
    return <div className="text-center py-12 text-red-600">{error}</div>;

  return (
    <div>
      <h2 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
        Pre-Orders Management
      </h2>

      <div className="flex flex-wrap gap-2 mb-8">
        {filterButtons.map((status) => (
          <button
            key={status}
            onClick={() =>
              setStatusFilter(status === "All" ? null : status.toLowerCase())
            }
            className={`px-4 py-2 rounded-full text-sm font-medium capitalize transition-all duration-300
              ${
                (status === "All" && !statusFilter) ||
                status.toLowerCase() === statusFilter
                  ? "bg-[#34324B] text-white"
                  : "bg-neutral-100 text-neutral-600 hover:bg-neutral-200"
              }`}
          >
            {status}
          </button>
        ))}
      </div>

      <input
        type="text"
        placeholder="Search orders..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-4 mb-8 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:border-transparent"
      />

      <div className="space-y-6">
        {filteredOrders.map((order) => (
          <div
            key={order.id}
            className="bg-white shadow-md rounded-lg p-6 border border-neutral-100 hover:border-neutral-200 transition-all duration-300"
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="font-cabinet-grotesk text-xl font-semibold text-neutral-800">
                Pre-Order #{order.id}
              </h3>
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium capitalize ${getStatusStyle(
                  order.status
                )}`}
              >
                {order.status}
              </span>
            </div>

            <div className="text-neutral-600 space-y-2 mb-4">
              <p>
                Customer:{" "}
                <span className="text-neutral-800">
                  {order.userName ||
                    `${order.user?.firstName ?? ""} ${
                      order.user?.lastName ?? ""
                    }`}
                </span>
              </p>
              <p>
                Email:{" "}
                <span className="text-neutral-800">
                  {order.email || order.user?.email}
                </span>
              </p>
              <p>
                Order Date:{" "}
                <span className="text-neutral-800">
                  {new Date(
                    order.created_at || order.orderDate || ""
                  ).toLocaleString()}
                </span>
              </p>
            </div>

            <div className="bg-neutral-50 rounded-lg p-4 mb-4">
              <h4 className="font-cabinet-grotesk text-lg font-medium mb-3 text-neutral-800">
                Products
              </h4>
              <ul className="space-y-3">
                {order.items.map((item, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <div>
                      <p className="text-neutral-800">
                        {item.product.display_name}
                      </p>
                      {item.product.size && (
                        <p className="text-sm text-neutral-600">
                          Size: {item.product.size}
                        </p>
                      )}
                    </div>
                    <div className="text-right">
                      <p className="text-neutral-800">Qty: {item.quantity}</p>
                      <p className="text-neutral-600">${item.price_at_time}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex items-center gap-4">
              <select
                value={selectedStatus[order.id] || order.status}
                onChange={(e) =>
                  setSelectedStatus((prev) => ({
                    ...prev,
                    [order.id]: e.target.value,
                  }))
                }
                className="px-4 py-2 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:border-transparent"
              >
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>
              </select>
              <button
                onClick={() => handleStatusUpdate(order.id)}
                disabled={
                  !hasStatusChanged(order.id, order.status, selectedStatus)
                }
                className={`group px-6 py-2 rounded-full transition-all duration-300 inline-flex items-center
                  ${
                    hasStatusChanged(order.id, order.status, selectedStatus)
                      ? "bg-[#34324B] text-white hover:shadow-lg"
                      : "bg-neutral-100 text-neutral-400 cursor-not-allowed"
                  }`}
              >
                {hasStatusChanged(order.id, order.status, selectedStatus) ? (
                  <>
                    Confirm Change
                    <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">
                      →
                    </span>
                  </>
                ) : (
                  "No Changes"
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
