const getStatusStyle = (status: string) => {
  const styles: Record<string, string> = {
    pending: "bg-yellow-100 text-yellow-800",
    processing: "bg-blue-100 text-blue-800",
    shipped: "bg-green-100 text-green-800",
    delivered: "bg-neutral-100 text-neutral-800",
    cancelled: "bg-red-100 text-red-800",
  };
  return styles[status.toLowerCase()] || "bg-neutral-100 text-neutral-800";
};

export default getStatusStyle;
