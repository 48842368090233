import { api, API_BASE_URL } from "./api";

export interface AuthToken {
  token: string;
  expiresAt: string;
  role?: string; // Make role optional
}

export const auth = {
  async login(email: string): Promise<AuthToken> {
    const response = await api.login(email);
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    throw new Error("Login failed");
  },
  async verifyToken(
    token: string
  ): Promise<{ isValid: boolean; authToken?: AuthToken }> {
    try {
      const response = await fetch(
        `${API_BASE_URL}/auth/verify?token=${token}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data.token) {
          const authToken: AuthToken = {
            token: data.token,
            expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
            role: data.role,
          };
          return {
            isValid: true,
            authToken: authToken,
          };
        }
      }
      return { isValid: false };
    } catch (error) {
      console.error("Error verifying token:", error);
      return { isValid: false };
    }
  },

  async getJWT(token: string): Promise<string> {
    const response = await fetch(`${API_BASE_URL}/auth/jwt`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });
    const data = await response.json();
    return data.jwt;
  },

  setToken(token: AuthToken) {
    console.log("setToken", token);
    localStorage.setItem("authToken", JSON.stringify(token));
  },

  getToken(): AuthToken | null {
    const token = localStorage.getItem("authToken");
    return token ? JSON.parse(token) : null;
  },

  isAdmin(): boolean {
    const token = this.getToken();
    return token !== null && token.role === "admin";
  },

  removeToken() {
    localStorage.removeItem("authToken");
  },

  isAuthenticated(): boolean {
    const token = this.getToken();
    console.log("Token:", token?.expiresAt);
    return token !== null && new Date(token.expiresAt) > new Date();
  },

  logout() {
    this.removeToken();
  },
};
