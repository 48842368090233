import React from "react";

export const AdminDashboard: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Dashboard</h2>
      {/* Add dashboard content here */}
    </div>
  );
};
