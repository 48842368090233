import React from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../services/api";

interface CartSummaryProps {
  cartItems: { [key: string]: number };
  products: Product[];
}

export const CartSummary: React.FC<CartSummaryProps> = ({
  cartItems,
  products,
}) => {
  const navigate = useNavigate();

  const calculateTotal = () => {
    return products.reduce((total, product) => {
      return total + (cartItems[product.id] || 0) * Number(product.price);
    }, 0);
  };

  const hasItemsInCart = Object.values(cartItems).some(
    (quantity) => quantity > 0
  );

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-neutral-200 p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div>
          <p className="text-sm text-neutral-600">
            Total Items: {Object.values(cartItems).reduce((a, b) => a + b, 0)}
          </p>
          <p className="font-cabinet-grotesk text-xl font-bold text-neutral-800">
            Total: ${calculateTotal().toFixed(2)}
          </p>
        </div>
        <button
          onClick={() => navigate("/pre-order")}
          className={`bg-[#34324B] text-white px-6 py-2.5 rounded-full text-base font-semibold 
                     focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:ring-opacity-50 
                     transition duration-300 ease-in-out transform shadow-lg
                     ${
                       hasItemsInCart
                         ? "hover:bg-[#2a2940] hover:-translate-y-1"
                         : "opacity-50 cursor-not-allowed"
                     }`}
          disabled={!hasItemsInCart}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};
