import React, { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Product } from "../services/api";
import { CartSummary } from "./CartSummary";

interface Ingredient {
  name: string;
  benefits: string;
}

interface ProductPageProps {
  products: Product[];
  onAddToCart: (selections: { [key: string]: number }) => void;
  cartItems: { [key: string]: number };
}

export const ProductPage: React.FC<ProductPageProps> = ({
  products,
  onAddToCart,
  cartItems,
}) => {
  const { baseProductName } = useParams<{ baseProductName: string }>();
  const navigate = useNavigate();

  const productVariants = products.filter(
    (p) => p.base_name === decodeURIComponent(baseProductName || "")
  );
  const baseProduct = productVariants[0];

  const parsedIngredients = useMemo((): Ingredient[] => {
    if (!baseProduct) return [];
    const matches = baseProduct.description.match(
      /Ingredients & Benefits:(.+)/i
    );
    if (!matches) return [];
    return matches[1]
      .split("-")
      .map((s) => s.trim())
      .filter(Boolean)
      .map((section) => {
        const [name, ...rest] = section.split(":");
        return { name: name.trim(), benefits: rest.join(":").trim() };
      })
      .filter((ing) => ing.name && ing.benefits);
  }, [baseProduct]);

  if (!baseProduct) return <div>Product not found</div>;

  const handleQuantityChange = (id: string, change: number) => {
    const q = Math.max(0, (cartItems[id] || 0) + change);
    onAddToCart({ [id]: q });
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8 max-w-7xl pb-32">
        <button
          onClick={() => navigate("/products")}
          className="mb-6 text-neutral-600 hover:text-neutral-800 flex items-center"
        >
          ← Back to Products
        </button>

        <h1
          className="text-3xl font-bold text-neutral-800 mb-6 
          text-left 
          md:text-center 
          lg:text-left lg:ml-[52%] lg:max-w-xl"
        >
          {baseProduct.base_name}
        </h1>

        <div className="grid lg:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div className="flex justify-center">
              <img
                src={baseProduct.image_url}
                alt={baseProduct.base_name}
                className="w-full max-w-md h-auto object-contain rounded-lg shadow-lg"
              />
            </div>

            <div className="space-y-4">
              <h2 className="text-xl font-semibold mb-2">Available Sizes</h2>
              {productVariants.map((variant) => (
                <div
                  key={variant.id}
                  className="border rounded-lg p-4 flex justify-between items-center hover:border-primary-300 transition-colors"
                >
                  <div>
                    <h3 className="font-semibold">{variant.size}</h3>
                    <p className="text-neutral-600">
                      ${Number(variant.price).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={() =>
                        handleQuantityChange(variant.id.toString(), -1)
                      }
                      className="w-8 h-8 rounded-full bg-neutral-100 flex items-center justify-center hover:bg-neutral-200 disabled:opacity-50"
                      disabled={!cartItems[variant.id]}
                    >
                      -
                    </button>
                    <span className="w-8 text-center font-medium">
                      {cartItems[variant.id] || 0}
                    </span>
                    <button
                      onClick={() =>
                        handleQuantityChange(variant.id.toString(), 1)
                      }
                      className="w-8 h-8 rounded-full bg-neutral-100 flex items-center justify-center hover:bg-neutral-200"
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:max-w-xl">
            <p className="text-neutral-600 mb-8">
              {baseProduct.description
                .split("Ingredients & Benefits:")[0]
                .trim()}
            </p>

            {parsedIngredients.length > 0 && (
              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  Key Ingredients & Benefits
                </h2>
                <div className="space-y-4">
                  {parsedIngredients.map((ing, i) => (
                    <div
                      key={i}
                      className="border-b border-neutral-200 pb-4 last:border-0"
                    >
                      <h3 className="font-semibold text-lg text-neutral-800 mb-1">
                        {ing.name}
                      </h3>
                      <p className="text-neutral-600">{ing.benefits}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CartSummary cartItems={cartItems} products={products} />
    </>
  );
};
